<template>
  <div class="SlapitonVideoBannerComponent">
    <v-container fluid>
      <v-row>
        <v-col align-self="center" align="center">
          <v-carousel   delimiter-icon="mdi-checkbox-blank-circle-outline" height="60vh !important" v-model="index" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="true" class="mt-8 Carousel" cycle :interval="45000">
            <v-carousel-item v-for="(item, index) in items" v-bind:key="index">
              <v-container fluid class="fill-height">
                <v-row>
                  <v-col align-self="center" align="center">
                    <video :ref="'vc'+index" class="Video" :src="item">
                    </video>
                    <v-btn :ref="'bvc'+index" rounded x-large color="red" dark class="Play" @click="play('vc'+index, 'bvc'+index)">Play</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col align-self="center" align="center" sm="12" md="6">
          <v-img :src="require('@/assets/slapItOn/manoscontexto-01.png')" contain height="100%" max-width="600px"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_SlapitonVideoBannerComponent.scss'
export default {
  name: 'SlapitonVideoBannerComponent',
  data () {
    return {
      items: [
        require('@/assets/aquarium/videos/1.mp4')
      ]
    }
  },
  methods: {
    play (value, button) {
      this.$refs[value][0].play()
      this.$refs[value][0].controls = true
      this.$refs[button][0].$el.style.display = 'none'
    }
  }
}
</script>

<style scoped>

</style>
