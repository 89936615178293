<template>
    <div class="TopBannerComponent">
      <v-carousel  delimiter-icon="mdi-checkbox-blank-circle-outline" height="100%"
                   v-model="index" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="false" class="Carousel" cycle :interval="120000">
        <v-carousel-item v-for="(item, index) in items" v-bind:key="index">
          <v-container fluid class="fill-height ma-0 pa-0">
            <v-row dense>
              <v-col align-self="center" align="center">
                <v-img :src="item" width="100vw" height="100%" max-width="2048px" contain>
                  <div class="TextBanner">
                    Start the Journey
                    <div>
                      <v-btn rounded x-large color="#5BB0FE" dark class="text-capitalize BottomBanner">Watch More</v-btn>
                    </div>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-carousel-item>
      </v-carousel>
    </div>
</template>

<script>
import './_TopBannerComponent.scss'
export default {
  name: 'TopBannerComponent',
  data () {
    return {
      index: 0,
      items: [
        require('@/assets/aquarium/bannerTop.png'),
        require('@/assets/aquarium/bannerTop.png')
      ]
    }
  }
}
</script>
