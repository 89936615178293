<template>
    <div class="CardDetailComponent">
      <v-container fluid class="fill-height">
        <v-row>
          <v-col align-self="center" align="center">
            <v-progress-circular size="250" indeterminate width="20" v-if="loading" />
            <div class="dialog" v-if="!loading">
              <div class="closeButton">
                <v-btn class="closeButtonIcon" elevation="0" small fab color="#ffffff55" @click="$store.state.cardDetail = null">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <Splide :options="{ drag: 'free', rewind: true, perPage: perPage, perMove: 1, trimSpace: false }" v-if="item.type === 'Collection' || $vuetify.breakpoint.width < 960" style="border-radius: 15px">
                <SplideSlide v-for="(asset, index) in assets" v-bind:key="index" :class="{ ContentBox : true, Aquarium: $store.state.context === 'Aquarium' }" style="border-radius: 0">
                  <v-img v-if="asset.previews.length > 1 ? asset.previews[1].type !== 'video' && asset.previews[1].type !== 'render' : true" :src="asset.previews[0].url" height="400px"></v-img>
                  <VideoPlayer v-if="asset.previews.length > 1 ? asset.previews[1].type === 'video' : false" :src="asset.previews[1].url" :width="'100%'" :height="'400px'" :poster="asset.previews[0].url"></VideoPlayer>
                  <ModelPlayer v-if="asset.previews.length > 1 ? asset.previews[1].type === 'render' : false" :src="asset.previews[1].url" :width="'100%'" :height="'400px'" :poster="asset.previews[0].url"></ModelPlayer>
                </SplideSlide>
              </Splide>
              <div>
                <v-container fluid class="ma-0 pa-0">
                  <v-row dense class="ma-0 pa-0">
                    <v-col class="ma-0 pa-0" v-if="item.type === 'Single' && $vuetify.breakpoint.width >= 960" style="transition: .3s">
                      <div v-for="(asset, index) in assets" v-bind:key="index" :style="{ height: '80vh', width: '100%' }" :class="{ ContentBox : true, Aquarium: $store.state.context === 'Aquarium' }" style="max-height: calc(80vh - 35px)">
                        <div @mouseover="hiddenInfo = true" @mouseleave="hiddenInfo = false" v-if="asset.previews.length > 1 ? asset.previews[1].type !== 'video' && asset.previews[1].type !== 'render' : true" :style="{ 'border-radius': '15px', background: 'url('+ asset.previews[0].url + ')', 'background-repeat': 'no-repeat', 'background-position': 'center center', 'background-size': hiddenInfo ? 'contain' : 'cover', height: 'calc(80vh - 35px)', width: '100%' }"></div>
                        <VideoPlayer v-if="asset.previews.length > 1 ? asset.previews[1].type === 'video' : false" :src="asset.previews[1].url" :width="'100%'" :height="'calc(80vh - 35px)'" :poster="asset.previews[0].url" radius="15px"></VideoPlayer>
                        <ModelPlayer v-if="asset.previews.length > 1 ? asset.previews[1].type === 'render' : false" :src="asset.previews[1].url" :width="'100%'" :height="'80vh'" :poster="asset.previews[0].url" radius="15px"></ModelPlayer>
                      </div>
                    </v-col>
                    <v-col v-if="!hiddenInfo" class="ma-0 pa-0">
                      <v-container fluid class="mt-2 fill-height">
                        <v-row dense>
                          <v-col align="left">
                            <div>
                              <div class="title py-2">{{ item.type === 'Collection' ? item.collection.name : item.title }}</div>
                              <div class="subtitle" v-if="item.type === 'Collection'">Collection / {{ item.collection.count }} pieces</div>
                              <div class="subtitle" v-if="item.type === 'Single' && item.pieces > 1">{{ item.pieces }} pieces / {{ item.available }} available</div>
                              <div class="subtitle" v-if="item.type === 'Single' && item.pieces === 1">Unique</div>
                            </div>
                            <div align="left" v-if="item.type === 'Single'">
                              <div class="description" v-if="$vuetify.breakpoint.width >= 960">
                                {{ item.type === 'Collection' ? item.collection.description : item.metadata.description | truncate(256) }}
                              </div>
                              <div class="description" v-if="$vuetify.breakpoint.width < 960">
                                {{ item.type === 'Collection' ? item.collection.description : item.metadata.description | truncate(128) }}
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col align="left" :cols="$vuetify.breakpoint.width < 960 || item.type === 'Single' ? 12 : ''">
                            <div class="description" v-if="$vuetify.breakpoint.width >= 960 && item.type !== 'Single'">
                              {{ item.type === 'Collection' ? item.collection.description : item.metadata.description | truncate(256) }}
                            </div>
                            <div class="description" v-if="$vuetify.breakpoint.width < 960 && item.type !== 'Single'">
                              {{ item.type === 'Collection' ? item.collection.description : item.metadata.description | truncate(128) }}
                            </div>
                            <div class="my-auto" align="center" v-if="item.type === 'Single' || $vuetify.breakpoint.width < 960">
                              <a @click="getVirtualLink">
                                <div align="center">
                                  <v-img :src="require('@/assets/cube.png')" contain width="20%" max-width="128px" max-height="128px"></v-img>
                                  <span class="overline">Virtual Gallery</span>
                                </div>
                              </a>
                            </div>
                            <div v-if="$store.state.context === 'Aquarium' && false" align="center" class="mt-10" style="display: flex; flex-direction: column; align-items: center">
                              <div class="description">Choose Size</div>
                              <div>
                                <v-radio-group
                                  row
                                >
                                  <v-radio
                                    label="Ginormorous"
                                    value="1"
                                  ></v-radio>
                                  <v-radio
                                    label="Jumbo"
                                    value="2"
                                  ></v-radio>
                                  <v-radio
                                    label="Extra Large"
                                    value="3"
                                  ></v-radio>
                                  <v-radio
                                    label="Large"
                                    value="4"
                                  ></v-radio>
                                </v-radio-group>
                              </div>
                            </div>
                            <div class="d-inline-flex authorLabel">
                              <v-avatar size="32"><v-img :src="item.avatar ? item.avatar : defaultAvatar" contain max-height="32px" max-width="32px" /></v-avatar>
                              <div class="ml-2">
                                <div class="CardSub">Created by</div>
                                <div class="CardAuthor">{{ item.author }}</div>
                              </div>
                            </div>
                          </v-col>
                          <v-col v-if="item.type !== 'Single'" cols="2">
                            <div class="my-auto" align="center" v-if="item.type !== 'Single' && $vuetify.breakpoint.width > 960">
                              <a @click="getVirtualLink">
                                <div align="center">
                                <v-img :src="require('@/assets/cube.png')" contain max-width="128px" max-height="128px"></v-img>
                                <span class="overline">Virtual Gallery</span>
                                </div>
                              </a>
                            </div>
                          </v-col>
                          <v-col align="right">
                            <div class="d-inline-flex mr-5" v-if="$vuetify.breakpoint.width >= 960">
                              <div class="text-h1 pt-1">
                                $ {{ item.collection.individual ? item.collection.prices[item.hash] : item.collection.price }}
                              </div>
                              <div class="ml-3">
                                <div class="text-h4">Collection Price</div>
                                <div class="text-h2">{{ currencies.find(currency => currency.code === item.collection.currency).name }}</div>
                              </div>
                            </div>
                            <div align="right">
                              <v-img :src="this.item.qr" contain width="30%" height="30%" max-width="300px" max-height="300px" v-if="!$device.mobile" />
                              <v-btn style="width: 30%; max-width: 300px" rounded color="#70AFD0" x-large target="_blank" :href="this.item.link">pay now</v-btn>
                            </div>
                            <div class="d-inline-flex mr-2" v-if="$vuetify.breakpoint.width < 960">
                              <div class="text-h5 pt-1">
                                $ {{ item.collection.individual ? item.collection.prices[item.hash] : item.collection.price }}
                              </div>
                              <div class="ml-3">
                                <div style="font-size: 0.8rem">Collection Price</div>
                                <div style="font-size: 0.8rem">{{ currencies.find(currency => currency.code === item.collection.currency).name }}</div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_CardDetailComponent.scss'
import { generateQR } from 'id4good.auth'
import VideoPlayer from '../VideoPlayer/VideoPlayer'
import ModelPlayer from '../ModelPlayer/ModelPlayer'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
export default {
  name: 'CardDetailComponent',
  components: { ModelPlayer, VideoPlayer, Splide, SplideSlide },
  data () {
    return {
      item: null,
      loading: true,
      hiddenInfo: false,
      assets: [],
      defaultAvatar: require('@/assets/default_profile.svg'),
      perPage: 10,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ]
    }
  },
  created () {
    console.log('created')
  },
  mounted () {
    console.log('mount')
    this.perPage = Math.round(this.$vuetify.breakpoint.width / 450)
    this.item = this.$store.state.cardDetail
    console.log(this.item)
    this.loadCollection()
  },
  updated () {
    console.log('updated')
  },
  methods: {
    getVirtualLink () {
      const revolved = this.$router.resolve({
        name: 'Virtual',
        params: { id: this.item.collection.key }
      })
      const base = (window.location.href.split('#'))[0]
      if (this.item.collection.template) {
        window.location.href = base + revolved.href + '?template=' + this.item.collection.template
      } else {
        window.location.href = base + revolved.href
      }
      this.$store.state.cardDetail = null
    },
    loadCollection () {
      this.$axios.get('/api/collections/getCollection', {
        params: {
          hash: this.item.collection.key,
          crc: Date.now()
        }
      }).then((result) => {
        console.log(result)
        const base = (window.location.href.split('#'))[0]
        this.user = result.data.user
        this.collection = result.data.collection
        let count = this.collection.assets.length
        const revolved = this.$router.resolve({
          name: 'Payment',
          params: { id: this.item.collection.key.toString().replaceAll('/', '%2F') },
          query: { shipping: this.collection.shipping ? this.collection.shipping : undefined, context: this.$store.state.context !== 'default' ? this.$store.state.context : undefined }
        })
        this.item.link = base + revolved.href
        generateQR(base + revolved.href).then(qr => {
          this.item.qr = qr
        })
        for (const key in this.collection.assets) {
          this.$axios.get('/api/assets/getAssetInfo', {
            params: {
              hash: this.collection.assets[key],
              crc: parseInt(Date.now() / 100000)
            }
          }).then((result) => {
            this.assets[key] = result.data.asset
            count--
            if (count === 0) {
              this.loading = false
            }
          })
        }
      }).catch(() => {
        console.log('error')
        this.loading = false
      })
    }
  }
}
</script>
