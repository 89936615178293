<template>
    <div class="AquariumAboutView">
        <v-container fluid class="ma-0 pa-0">
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <AquariumSeriesComponent />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <div class="trending display-4">COMING SOON</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <SeparatorAquariumComponent />
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_AquariumAboutView.scss'
import AquariumSeriesComponent from '../../../components/Aquarium/AquariumSeriesComponent/AquariumSeriesComponent'
import SeparatorAquariumComponent from '../../../components/Aquarium/SeparatorAquariumComponent/SeparatorAquariumComponent'

export default {
  name: 'AquariumAboutView',
  components: { SeparatorAquariumComponent, AquariumSeriesComponent },
  mounted () {
    this.setColors()
  }
}
</script>
