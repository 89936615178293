<template>
  <div class="SlapitonFounderSectionComponent">
    <v-container>
            <v-row>
              <v-col>
                <h1 class="textSlapiton my-10">
                  Founded by <span class="slap">TRUE</span> legends in the World of Sports
                </h1>
              </v-col>
            </v-row>
          </v-container>
    <v-container class="mb-5 px-10" fluid>
      <v-row>
        <v-col
          v-for="n in items"
          :key="n.id"
          cols="12"
          sm="12"
          md="3">
          <v-card
            class="mx-auto rounded-xl"
            width="400"
            height="650"
            color="rgba(0,0,0,0.5)"
          >
            <v-img
              :src=n.img
              height="500"
              class="rounded-xl"
            >
            </v-img>
            <br>
            <p class="text-center white--text textabovebutton text-h5">
              {{n.name}}
            </p>
            <div>
              <v-btn
                color="#FF0000"
                dark
                absolute
                x-large
                height="50"
                width="200"
                :style="{left: '50%', transform:'translateX(-50%)'}"
                class="rounded-pill jainesh"
              >
                <span class="white--text">{{n.button}}</span>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_SlapitonFounderSectionComponent.scss'

export default {
  name: 'SlapitonFounderSectionComponent',
  data: () => ({
    items: [
      {
        id: 1,
        name: 'Mike Vanderjagt',
        icon: 'mdi-rocket-launch-outline',
        button: 'More',
        img: require('@/assets/slapItOn/founders/Mike.png'),
        color: 'solid purple'

      },
      {
        id: 2,
        name: 'Johnny Damon',
        button: 'More',
        icon: 'mdi-message-name-outline ',
        color: 'solid Aqua',
        img: require('@/assets/slapItOn/founders/Johnny.png')
      },
      {
        id: 3,
        name: 'Cobi Jones',
        button: 'More',
        icon: 'mdi-cart-minus',
        color: 'solid #FF5733',
        img: require('@/assets/slapItOn/founders/Cobi.png')
      },
      {
        id: 4,
        name: 'Steve Smith',
        button: 'More',
        img: require('@/assets/slapItOn/founders/Steve.png'),
        color: 'solid purple'
      }
    ]
  })

}
</script>
