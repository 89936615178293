<template>
    <div class="DownloadAquariumAppComponent">
      <v-container fluid class="fill-height">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="text-h2 font-weight-thin white--text">
              DOWNLOAD THE SLAP<span style="color: #a6352c">IT</span>ON AR APP NOW
            </div>
            <div class="py-10 d-inline-flex">
              <div>
              <a href="https://play.google.com/store/apps/details?id=com.slapiton.slapitonar" target="_blank">
                <v-img :src="require('@/assets/apple-store-badge.svg')" max-height="80px" height="80px" width="240px" max-width="240px" contain class="mx-5"></v-img>
              </a>
              </div>
              <div>
              <a href="https://apps.apple.com/us/app/slapitonar/id1540844011" target="_blank">
                <v-img :src="require('@/assets/google-play-badge.png')" height="80px" width="240px" max-height="80px" max-width="240px" contain class="mx-5"></v-img>
              </a>
              </div>
            </div>
            <div class="closeFloatButton">
              <v-btn fab small @click="$store.state.aquarium.download = false"><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_DownloadAquariumAppComponent.scss'
export default {
  name: 'DownloadAquariumAppComponent'
}
</script>
