<template>
  <div class="ArYourWorldComponent">
      <v-container fluid class="my-10">
        <v-row>
          <v-col cols="6" align-self="center" align="center">
            <div class="Content mx-auto mt-5" align="center">
              <v-img align-self="center" align="center" :src="require('@/assets/slapItOn/ayw-01.png')" max-width="650" contain style="text-align: center" ></v-img>
              <div class="TextAr" align="left">
                Create your very own AR Aquarium with SlapItOn<br>
                A activated prints in 4 different sizes to chose from!
                <a @click="$store.state.aquarium.download = true">
                <v-img class="mt-8" :src="require('@/assets/slapItOn/gettheapp.svg')" contain max-width="150px"></v-img>
                </a>
              </div>
            </div>
          </v-col>
          <v-col align-self="center" align="center">
            <v-carousel   delimiter-icon="mdi-checkbox-blank-circle-outline" v-model="index" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="true" class="Carousel" cycle :interval="45000">
              <v-carousel-item v-for="(item, index) in items" v-bind:key="index">
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <video :ref="'vc'+index" class="Video" :src="item">
                      </video>
                      <v-btn :ref="'bvc'+index" rounded x-large color="red" dark class="Play" @click="play('vc'+index, 'bvc'+index)">Play</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_ArYourWorldComponent.scss'
export default {
  name: 'ArYourWorldComponent',
  data () {
    return {
      items: [
        require('@/assets/aquarium/videos/1.mp4')
      ]
    }
  },
  methods: {
    play (value, button) {
      this.$refs[value][0].play()
      this.$refs[value][0].controls = true
      this.$refs[button][0].$el.style.display = 'none'
    }
  }
}
</script>
