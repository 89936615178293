<template>
    <div class="StoriesComponent">
      <Splide class="mb-15" :options="{ drag: 'free', rewind: true, perPage: perPage, perMove: 1, trimSpace: false, autoplay: true }" v-if="ready && false">
        <SplideSlide v-for="(item, index) in items" v-bind:key="index" class="mb-10">
          <v-card
            class="ma-5 rounded-xl"
            height="450"
            width="350"
            style="box-shadow: 5px 5px 3px #00000066"
          >
            <v-img
              :src=item.src
              height="450px"
            >
              <div class="mt-16">
                <v-btn
                  color="#74aff8"
                  dark
                  absolute
                  bottom

                  x-large
                  height="50"
                  width="300"
                  :style="{left: '50%', transform:'translateX(-50%)'}"
                  class="mt-16 my-sm-16 rounded-pill"
                  @click="$emit('selectStory', item.label)"
                >
                  <span class="white--text">{{ item.text }}</span>
                </v-btn>
              </div>
            </v-img>
          </v-card>
        </SplideSlide>
      </Splide>
      <v-container class="mb-10" v-if="ready">
        <v-row>
          <v-col cols0="12" md="6" lg="4" xl="3" v-for="(item, index) in items.slice(0, limit)" v-bind:key="index" align-self="center" align="center">
            <v-card
              class="ma-5 rounded-xl"
              height="450"
              width="350"
              style="box-shadow: 5px 5px 3px #00000066"
            >
              <v-img
                :src=item.src
                height="450px"
              >
                <div class="mt-16">
                  <v-btn
                    color="#74aff8"
                    dark
                    absolute
                    bottom

                    x-large
                    height="50"
                    width="300"
                    :style="{left: '50%', transform:'translateX(-50%)'}"
                    class="mt-16 my-sm-16 rounded-pill"
                    @click="$emit('selectStory', item.label)"
                  >
                    <span class="white--text">{{ item.text }}</span>
                  </v-btn>
                </div>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols0="12" md="6" lg="4" xl="3"></v-col>
          <v-col cols0="12" md="6" lg="4" xl="3"></v-col>
          <v-col cols0="12" md="6" lg="4" xl="3"></v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_StoriesComponent.scss'
import { Splide, SplideSlide } from '@splidejs/vue-splide'

export default {
  name: 'StoriesComponent',
  data: () => ({
    show: false,
    ready: false,
    perPage: 3,
    components: {
      Splide,
      SplideSlide
    },
    items: [
      {
        id: 1,
        src: require('@/assets/aquarium/stories/01.jpg'),
        text: 'Sharks',
        label: 'Sharks'
      },
      {
        id: 2,
        src: require('@/assets/aquarium/stories/02.jpg'),
        text: 'Reef Fish',
        label: 'Reef Fish'
      },
      {
        id: 3,
        src: require('@/assets/aquarium/stories/03.jpg'),
        text: 'Turtles',
        label: 'Turtles'
      },
      {
        id: 4,
        src: require('@/assets/aquarium/stories/04.jpg'),
        text: 'Reef Creatures',
        label: 'Reef Creatures'
      },
      {
        id: 5,
        src: require('@/assets/aquarium/stories/05.jpg'),
        text: 'Coral',
        label: 'Coral'
      },
      {
        id: 6,
        src: require('@/assets/aquarium/stories/06.jpg'),
        text: 'Marine Mammals',
        label: 'Marine Mammals'
      },
      {
        id: 7,
        src: require('@/assets/aquarium/stories/07.jpg'),
        text: 'Under the Sea Decor',
        label: 'Under the Sea Decor'
      },
      {
        id: 8,
        src: require('@/assets/aquarium/stories/08.jpg'),
        text: 'Join the Fish of Month',
        label: 'Subscription'
      },
      {
        id: 9,
        src: require('@/assets/aquarium/stories/02.jpg'),
        text: 'Collections',
        label: 'Collection'
      },
      {
        id: 10,
        src: require('@/assets/aquarium/stories/04.jpg'),
        text: 'Special Offers',
        label: 'Special Offers'
      }

    ],
    model: null
  }),
  created () {
    this.perPage = Math.round(this.$vuetify.breakpoint.width / 400) - 1
    if (this.perPage === 0) {
      this.perPage = 1
    }
    console.log(this.perPage)
    this.ready = true
  },
  methods: {
    showCardDetail (item) {
      console.log('show')
      this.$store.state.cardDetail = item
    }
  }
}
</script>
