<template>
    <div class="FooterMenuComponent">
        <v-container fluid>
          <v-row>
            <v-col align-self="flex-start" align="left" >
              <div class="title pl-5 mb-3">Explore</div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">All NFT's</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Departments</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Trending</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Partners</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Activity</v-btn></div>
            </v-col>
            <v-col align-self="flex-start" align="left">
              <div class="title pl-5 mb-3">Create</div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Get Started</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Plan</v-btn></div>
            </v-col>
            <v-col align-self="flex-start" align="left">
              <div class="title pl-5 mb-3">Company</div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">About</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Empowerment</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Products</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Careers</v-btn></div>
            </v-col>
            <v-col align-self="flex-start" align="left">
              <div class="title pl-5 mb-3">Profile</div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">ID4Good</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Wallet4Good</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Account</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Assets</v-btn></div>
            </v-col>
            <v-col align-self="flex-start" align="left">
              <div class="title pl-5 mb-3">Resources</div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Help Center</v-btn></div>
              <div><v-btn height="32" :x-large="$vuetify.breakpoint.lgAndUp" plain dark class="pl-5 text-capitalize">Contact</v-btn></div>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_FooterMenuComponent.scss'
export default {
  name: 'FooterMenuComponent'
}
</script>
