<template>
  <div class="SlapItOn">
    <v-container fluid class="ma-0 pa-0">
      <v-row dense>
        <v-col align-self="center" align="center" class="pa-0">
          <SlapitonVideoBannerComponent></SlapitonVideoBannerComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align-self="center" align="center" style="background-color: #ffffff1a; color: white">
          <div class="my-15 Banner">
            <div>We make the world’s finest augmented reality decals</div>
            <div>You can <span style="color: red; font-size: 150%; position: relative; top: 10px">SLAP</span> It On anywhere!!!</div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align-self="center" align="center" class="pa-0">
          <ImageSectionComponent></ImageSectionComponent>
        </v-col>
      </v-row>
      <v-row dense style="background-color: #ffffff1a">
        <v-col align-self="center" align="center" class="pa-0">
          <SlapitonFounderSectionComponent></SlapitonFounderSectionComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align-self="center" align="center" class="pa-0">
          <ArYourWorldComponent></ArYourWorldComponent>
        </v-col>
      </v-row>
      <v-row dense style="background-color: #ffffff1a">
        <v-col align-self="center" align="center" class="pa-0">
          <div class="ml-10" align="left"><h1 class="trending">Collections</h1></div>
          <SlapitonCollectionsComponent></SlapitonCollectionsComponent>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align-self="center" align="center" class="pa-0">
          <SlapitonPopularCollectionsComponent :items="slider.top.filter(item => item.type === 'Collection' || item.collection.labels.indexOf('Collection') > 0)" v-if="slider.top.length > 0 && !loading"></SlapitonPopularCollectionsComponent>
        </v-col>
      </v-row>
      <v-row dense style="background-color: #ffffff1a">
        <v-col align-self="center" align="center" class="pa-0">
          <SlapitonMailingListComponent></SlapitonMailingListComponent>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_SlapItOn.scss'
import ImageSectionComponent from '../../components/SlapItOn/ImageSectionComponent/ImageSectionComponent'
import SlapitonFounderSectionComponent from '../../components/SlapItOn/SlapitonFounderSectionComponent/SlapitonFounderSectionComponent'
import ArYourWorldComponent from '../../components/SlapItOn/ArYourWorldComponent/ArYourWorldComponent'
import SlapitonCollectionsComponent from '../../components/SlapItOn/SlapitonCollectionsComponent/SlapitonCollectionsComponent'
import SlapitonMailingListComponent from '../../components/SlapItOn/SlapitonMailingListComponent/SlapitonMailingListComponent'
import SlapitonPopularCollectionsComponent from '../../components/SlapItOn/SlapitonPopularCollectionsComponent/SlapitonPopularCollectionsComponent'
import SlapitonVideoBannerComponent from '../../components/SlapItOn/SlapitonVideoBannerComponent/SlapitonVideoBannerComponent'
import assets from '../../mixins/assets'

export default {
  name: 'SlapItOn',
  components: {
    SlapitonVideoBannerComponent,
    SlapitonPopularCollectionsComponent,
    SlapitonMailingListComponent,
    SlapitonCollectionsComponent,
    ArYourWorldComponent,
    SlapitonFounderSectionComponent,
    ImageSectionComponent
  },
  data () {
    return {
      loading: true,
      currencies: [
        { code: 't4g', name: 'T4G-USD' },
        { code: 't4gcad', name: 'T4G-CAD' },
        { code: 't4gbrl', name: 'T4G-BRL' },
        { code: 't4gclp', name: 'T4G-CLP' }
      ],
      slider: {
        top: []
      }
    }
  },
  mixins: [assets],
  mounted () {
    this.getTopSelling()
  },
  methods: {
    getTopSelling () {
      this.$axios.get('/api/data/getTopSelling', {
        params: {
          filter: 'SlapItOn'
        }
      }).then(async (result) => {
        for (const item of result.data.items) {
          item.metadata = await this.getMetadata('https://asset4good.com/' + item.cid)
          item.title = item.metadata.name ? item.metadata.name : item.collection.name
          item.author = item.identity.name
          item.avatar = item.identity.avatar
          item.pieces = parseFloat(item.metadata.quantity)
          item.available = parseFloat(1)
          item.type = item.collection.count > 1 ? 'Collection' : 'Single'
          item.image = item.previews ? item.previews[0].url : item.metadata.image_url
          this.slider.top.push(item)
        }
        this.loading = false
      })
    }
  }
}
</script>
