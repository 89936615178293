<template>
    <div class="SlapitonCollectionsComponent">
      <v-sheet
        class="mx-auto pb-8"
        elevation="0"
        max-width="fill-width"
        height=""
        style="background-color: transparent !important;"
      >
        <v-slide-group
          v-model="model"
          class="pa-8"
          active-class="success"
          show-arrows
        >
          <v-slide-item
            v-for="n in items"
            :key="n"
            v-slot="{ active, toggle }"
          >
            <v-card
              class="ma-8 rounded-xl"
              height="500"
              width="380"
              @click="toggle"
              style="box-shadow: 5px 5px 3px #00000066"
            >
              <v-img
                :src=n.src
                height="500px"
              >
                <div class="mt-16">
                  <v-btn
                    color="#FF0000"
                    dark
                    absolute
                    bottom

                    x-large
                    height="50"
                    width="200"
                    :style="{left: '50%', transform:'translateX(-50%)'}"
                    class="mt-16 my-sm-16 rounded-pill"
                  >
                    <span class="white--text">{{n.text}}</span>
                  </v-btn>
                </div>
              </v-img>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
</template>

<script>
import './_SlapitonCollectionsComponent.scss'

export default {
  name: 'SlapitonCollectionsComponent',
  data: () => ({
    show: false,
    items: [
      {
        id: 1,
        src: require('@/assets/slapItOn/collections/Golf-01.png'),
        text: 'Sports'
      },
      {
        id: 2,
        src: require('@/assets/slapItOn/collections/Bowling-01.png'),
        text: 'Bowling'
      },
      {
        id: 3,
        src: require('@/assets/slapItOn/collections/Gaming-01.png'),
        text: 'Gaming'
      },
      {
        id: 4,
        src: require('@/assets/slapItOn/collections/Pets-01.png'),
        text: 'pet'
      },
      {
        id: 5,
        src: require('@/assets/slapItOn/collections/Art-01.png'),
        text: 'art'
      }

    ],
    rounded: [
      '0',
      'sm',
      'md',
      'lg',
      'xl',
      'pill',
      'circle'
    ],
    model: null
  })
}
</script>
