<template>
    <div class="FooterComponent">
      <v-footer class="footer" v-show="!$store.state.hiddenBar">
        <v-container fluid class="pa-0 ma-0">
          <v-row dense class="pa-0 ma-0" v-if="$route.name !== 'SlapItOn' && $route.name !== 'Aquarium'">
            <v-col class="pa-0 ma-0">
              <PartnersComponent />
            </v-col>
          </v-row>
          <v-row dense class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-container style="padding-top: 100px; padding-bottom: 100px">
                <v-row v-if="!$vuetify.breakpoint.lgAndUp">
                  <v-col align-self="center" align="center" class="d-inline-flex">
                    <v-spacer></v-spacer>
                    <v-img class="mx-3" :src="require('@/assets/facebook-white.svg')" width="32" height="32" max-width="32" contain />
                    <v-img class="mx-3" :src="require('@/assets/twitter-white.svg')" width="32" height="32" max-width="32" contain />
                    <v-btn class="mx-3" icon href="https://instagram.com/bloqs4good" target="_blank"><v-img class="mx-3" :src="require('@/assets/instagram-white.svg')" width="32" height="32" max-width="32" contain /></v-btn>
                    <v-btn class="mx-3" icon href="https://www.linkedin.com/company/bloqs4good" target="_blank"> <v-img class="mx-3" :src="require('@/assets/linkedin-white.svg')" width="32" height="32" max-width="32" contain /></v-btn>
                    <v-spacer></v-spacer>
                  </v-col>
                </v-row>
                <v-row v-if="!$vuetify.breakpoint.lgAndUp">
                  <v-col class="">
                    <hr style="border-color: #ffffff44">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left" align-self="center" :cols="$vuetify.breakpoint.lgAndUp ? 4 : 12" :class="{ 'd-inline-flex': !$vuetify.breakpoint.lgAndUp}">
                    <v-img :src="require('@/assets/dog-engine-white.svg')" contain max-width="120px" min-width="100px" width="10%" style="display: inline-block" />
                    <v-img class="ml-4" :src="require('@/assets/slapiton_logo.svg')" v-if="false" contain max-width="150px" min-width="100px" width="20%" style="display: inline-block"/>
                    <div v-if="$store.state.context === 'default'" :class="{ 'pl-2': true,  'pt-5': true,  'text-h5': $vuetify.breakpoint.lgAndUp, 'white--text': true }" style="font-weight: 300;">
                      Digital marketplace for crypto collectibles and non fungible tokens (NFTs).
                      <br>
                      Buy, sell and discover exclusive digital assets.
                    </div>
                    <div v-if="$store.state.context === 'Aquarium' || $store.state.context === 'SlapItOn' " :class="{ 'pl-2': true,  'pt-5': true,  'text-h5': $vuetify.breakpoint.lgAndUp, 'white--text': true }" style="font-weight: 300;">
                      SlapItOn is a proud partner of dept of Good.  Community focused platform where E-commerce and NFTs are combined.
                      <br>
                      Join Us.
                    </div>
                  </v-col>
                  <v-col v-if="$vuetify.breakpoint.lgAndUp">
                    <FooterMenuComponent />
                  </v-col>
                </v-row>
                <v-row v-if="!$vuetify.breakpoint.lgAndUp">
                  <v-col class="">
                    <hr style="border-color: #ffffff44">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="!$vuetify.breakpoint.lgAndUp">
                    <FooterMenuComponent />
                  </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.lgAndUp">
                  <v-col class="py-10 my-10">
                    <hr style="border-color: #ffffff44">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="left">
                    <div>
                      <script type="application/javascript" id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=6b720033-598c-48d9-bd5a-45bb963a93d4"> </script>
                    </div>
                  </v-col>
                  <v-col align-self="center" align="center" v-if="$vuetify.breakpoint.lgAndUp || $route.name !== 'SlapItOn'" class="d-inline-flex">
                    <v-spacer></v-spacer>
                    <v-img class="mx-3" :src="require('@/assets/facebook-white.svg')" width="32" height="32" max-width="32" contain />
                    <v-img class="mx-3" :src="require('@/assets/twitter-white.svg')" width="32" height="32" max-width="32" contain />
                    <v-btn class="mx-3" icon href="https://instagram.com/bloqs4good" target="_blank"><v-img class="mx-3" :src="require('@/assets/instagram-white.svg')" width="32" height="32" max-width="32" contain /></v-btn>
                    <v-btn class="mx-3" icon href="https://www.linkedin.com/company/bloqs4good" target="_blank"> <v-img class="mx-3" :src="require('@/assets/linkedin-white.svg')" width="32" height="32" max-width="32" contain /></v-btn>
                    <v-spacer></v-spacer>
                  </v-col>
                  <v-col align="right">
                    <v-img :src="require('@/assets/empowered.svg')" contain max-width="250px" min-width="100px" width="50%" v-if="$store.state.context === 'default'" />
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
    </div>
</template>

<script>
import './_FooterComponent.scss'
import PartnersComponent from '../PartnersComponent/PartnersComponent'
import FooterMenuComponent from '../FooterMenuComponent/FooterMenuComponent'
export default {
  name: 'FooterComponent',
  components: { FooterMenuComponent, PartnersComponent }
}
</script>
