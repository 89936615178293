<template>
    <div class="VideoClipsComponent">
        <v-container fluid>
          <v-row>
            <v-col align-self="center" align="center">
              <div class="Content" align="left">
                <span style="color: #5BB0FE">VIDEO</span><span>CLIPS</span>
                <div class="Text" align="left">
                  Get to know our products and digital content
                </div>
              </div>
            </v-col>
            <v-col align-self="center" align="center">
              <v-carousel  delimiter-icon="mdi-checkbox-blank-circle-outline" v-model="index" :hide-delimiter-background="true" :show-arrows-on-hover="true" :show-arrows="true" class="Carousel" cycle :interval="45000">
                <v-carousel-item v-for="(item, index) in items" v-bind:key="index">
                  <v-container fluid>
                    <v-row>
                      <v-col align-self="center" align="center">
                        <video :ref="'vc'+index" class="Video" :src="item">
                        </video>
                        <v-btn :ref="'bvc'+index" rounded x-large color="#5BB0FE" dark class="Play" @click="play('vc'+index, 'bvc'+index)">Play</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_VideoClipsComponent.scss'

export default {
  name: 'VideoClipsComponent',
  data () {
    return {
      items: [
        require('@/assets/aquarium/videos/1.mp4'),
        require('@/assets/aquarium/videos/2.mp4'),
        require('@/assets/aquarium/videos/3.mp4')
      ]
    }
  },
  methods: {
    play (value, button) {
      this.$refs[value][0].play()
      this.$refs[value][0].controls = true
      this.$refs[button][0].$el.style.display = 'none'
    }
  }
}
</script>
