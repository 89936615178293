<template>
    <div class="AquariumHelpView">
        <v-container fluid class="ma-0 pa-0">
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <VideoClipsComponent />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <SeparatorAquariumComponent />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center" class="pa-0">
              <div class="trending display-4">COMING SOON</div>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_AquariumHelpView.scss'
import SeparatorAquariumComponent from '../../../components/Aquarium/SeparatorAquariumComponent/SeparatorAquariumComponent'
import VideoClipsComponent from '../../../components/Aquarium/VideoClipsComponent/VideoClipsComponent'

export default {
  name: 'AquariumHelpView',
  components: { VideoClipsComponent, SeparatorAquariumComponent },
  mounted () {
  }
}
</script>
