import Vue from 'vue'
import './plugins/axios'
import './plugins/carousel'
import './plugins/device'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/confirm'
import './plugins/numeric'
import './plugins/slider'
import './plugins/filters'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCWVrI1d5-SHtiftFR2QkRzL9sE9XZYQYw',
  authDomain: 'deptofgood-bloqs4good.firebaseapp.com',
  projectId: 'deptofgood-bloqs4good',
  storageBucket: 'deptofgood-bloqs4good.appspot.com',
  messagingSenderId: '983105285373',
  appId: '1:983105285373:web:e671e44c66458562b92294',
  measurementId: 'G-B5JVQBSLPP'
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

Vue.prototype.$analytics = analytics
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created () {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
