<template>
    <div class="AquariumSeriesComponent">
      <div class="Content pt-10">
        <span style="color: #5BB0FE">AQUARIUM</span><span>SERIES</span>
        <div class="AppPromo">
          Create your very own AR Aquarium with SlapItOn A activated prints in 4 different sizes to chose from!
          <div class="my-5">
            <a @click="$store.state.aquarium.download = true">
            <v-img :src="require('@/assets/aquarium/get_the_app_header.svg')" contain height="48px"></v-img>
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import './_AquariumSeriesComponent.scss'
export default {
  name: 'AquariumSeriesComponent'
}
</script>
